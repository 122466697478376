import type { Payload } from "@local/payload-client/src/types";
import { ContentBlockServer } from "./ContentBlock";
import ButtonServer, { ButtonIntent, ButtonStretch } from "./ui/button";
import { YouTubeEmbed } from "@next/third-parties/google";
interface Props {
  block: Payload.YtVideoEmbedBlock;
}
export const YouTubeVideoBlockServer = ({
  block
}: Props) => {
  return <ContentBlockServer className="flex flex-col items-center px-4 pt-8 pb-6 mb-4 text-gray-900 md:px-20 lg:px-32" fullWidthBgColor="bg-gray-100" data-sentry-element="ContentBlockServer" data-sentry-component="YouTubeVideoBlockServer" data-sentry-source-file="YouTubeVideoBlock.tsx">
      <h2 className="mb-4 text-3xl font-bold">{block.headline}</h2>
      <p className="mb-6 text-lg font-normal leading-[1.333rem]">
        {block.text}
      </p>

      <div className="relative w-full mb-4 aspect-video">
        <YouTubeEmbed style="width:100%;margin:0 auto" videoid={block.youtubeVideoId} params="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" data-sentry-element="YouTubeEmbed" data-sentry-source-file="YouTubeVideoBlock.tsx" />
      </div>

      {block.ctaContent && block.ctaUrl && <>
          <ButtonServer content={block.ctaContent} stretch={ButtonStretch.full} url={block.ctaUrl} intent={ButtonIntent.DarkCTA} className="mb-4 md:invisible md:hidden" />
          <ButtonServer content={block.ctaContent} url={block.ctaUrl} intent={ButtonIntent.DarkCTA} className="invisible hidden mb-4 md:block md:visible" />
        </>}
    </ContentBlockServer>;
};
export default YouTubeVideoBlockServer;